import contrabandIconSrc from '@app/assets/images/contraband.webp'
import type {InventoryBackupItem} from '@app/common/api'
import {useAPIInventoryBackup} from '@app/common/api'
import type {FormType} from '@app/common/constants'
import {numberFormatter} from '@app/common/constants'
import {HoldUp} from '@app/components/Modal/HoldUp'
import {PlayerInfo} from '@app/components/Modal/PlayerInfo'
import {SmallHeading} from '@app/components/Modal/SmallHeading'
import {Spinner} from '@app/components/Spinner/Spinner'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {Box, Flex, Grid, Image, Stack, Text} from '@chakra-ui/react'
import type React from 'react'

export function InventoryBackup({
  inventoryId,
  formType,
  admin = false,
}: {
  inventoryId: string
  formType: FormType.FACTIONS_ROLLBACK | FormType.SKYBLOCK_ROLLBACK
  admin?: boolean
}): React.JSX.Element | null {
  const {data} = useAPIInventoryBackup(inventoryId, formType, admin)
  if (!inventoryId) return null
  if (!data)
    return (
      <Box my={16}>
        <Spinner />
      </Box>
    )

  const hasContraband = data.inventory.some(item => item.contraband) || data.armor.some(item => item.contraband)
  return (
    <Stack bgColor="gray.800" p="16px" rounded="lg" spacing="16px">
      {hasContraband && (
        <HoldUp>
          We detected contraband in this inventory. Grayed-out items have already been used in a previous rollback by
          another player. You will not get them back.
        </HoldUp>
      )}

      <Stack spacing="8px">
        <SmallHeading>Inventory</SmallHeading>
        <InventoryView items={data.inventory} />
      </Stack>
      <Stack spacing="8px">
        <SmallHeading>Armor</SmallHeading>
        <InventoryView items={data.armor} />
      </Stack>
      {data.deathCause.streak && (
        <Stack spacing="8px">
          <SmallHeading>Kill Streak</SmallHeading>
          <Text color="whiteAlpha.800" fontSize="sm" letterSpacing="tight">
            If accepted, we will add <strong>{numberFormatter.format(data.deathCause.streak)}</strong> to your current
            streak.
          </Text>
        </Stack>
      )}
      <Stack spacing="8px">
        <SmallHeading>Damager</SmallHeading>
        <PlayerInfo player={data.damagerName} />
      </Stack>
    </Stack>
  )
}

const nameParser = (name: string): string => {
  const parts = (name.split(':')[1] || name).split('_')
  return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
}

function InventoryView({items}: {items: InventoryBackupItem[]}): React.JSX.Element {
  return (
    <Grid gap={2} templateColumns="repeat(7, 1fr)">
      {items.map(item => (
        <Tooltip
          key={item.slot}
          label={
            <Stack fontSize="sm" letterSpacing="tight">
              <Stack spacing="0.5">
                <Text fontSize="md" fontWeight="semibold">
                  {nameParser(item.name)}
                </Text>
                {item.damage && (
                  <Text color="red.500" fontWeight="medium">
                    {item.damage} Damage
                  </Text>
                )}
                {item.enchantments.length > 0 && (
                  <Box>
                    {item.enchantments.map(enchantment => (
                      <Text fontWeight="light" key={enchantment}>
                        + {enchantment}
                      </Text>
                    ))}
                  </Box>
                )}
              </Stack>
              {item.contraband && (
                <Flex
                  align="center"
                  bg="red.500"
                  borderRadius="md"
                  color="white"
                  fontWeight="bold"
                  justify="center"
                  p={1}
                  textTransform="uppercase"
                >
                  Item Removed
                </Flex>
              )}
            </Stack>
          }
        >
          <Box display="grid" position="relative">
            {item.contraband && (
              <Image
                src={contrabandIconSrc}
                style={{position: 'absolute', zIndex: 1, width: 24, height: 24, bottom: 4, right: 4}}
              />
            )}
            <Image
              alt={nameParser(item.name)}
              boxShadow="lg"
              display="grid"
              draggable={false}
              filter={item.contraband ? 'grayscale(100%)' : 'none'}
              opacity={item.contraband ? 0.5 : 1}
              rounded="lg"
              src={item.image}
            />
          </Box>
        </Tooltip>
      ))}
    </Grid>
  )
}
