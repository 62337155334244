import type {ProxyServer} from '@app/common/api'
import {Indicator} from '@app/components/Indicator/Indicator'
import {AlertDialog} from '@app/components/Modal/AlertDialog'
import {Modal, ModalBody, ModalFooter} from '@app/components/Modal/Modal'
import {Tooltip} from '@app/components/Tooltip/Tooltip'
import {api} from '@app/hooks/useApi'
import {Button, FormControl, FormLabel, Heading, IconButton, Input, Stack, Switch, Text} from '@chakra-ui/react'
import {XMarkIcon} from '@heroicons/react/20/solid'
import {ArrowPathIcon, PlayIcon, WrenchIcon} from '@heroicons/react/24/solid'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'
import {toast} from 'react-hot-toast'

function EditProxyModal({
  isOpen,
  onClose,
  proxy,
}: {isOpen: boolean; onClose(): void; proxy: ProxyServer}): React.JSX.Element {
  const queryClient = useQueryClient()
  const [gracefulShutdownBarrier, setGracefulShutdownBarrier] = React.useState(proxy.state.gracefulShutdownBarrier)
  const [anticheatEnabled, setAnticheatEnabled] = React.useState(proxy.state.anticheatEnabled)

  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <Modal
      footer={
        <ModalFooter>
          <Button
            colorScheme="orange"
            isLoading={isLoading}
            onClick={async () => {
              const newProxy = proxy
              newProxy.state.gracefulShutdownBarrier = gracefulShutdownBarrier
              if (anticheatEnabled !== undefined) {
                newProxy.state.anticheatEnabled = anticheatEnabled
              }

              try {
                setIsLoading(true)
                await api.patch('/developer/proxy', [newProxy])

                await queryClient.invalidateQueries({queryKey: ['developer', 'proxy', 'list']})

                onClose()
              } catch {
                toast.error('Failed to push proxy data')
              } finally {
                setIsLoading(false)
              }
            }}
          >
            {'Save Changes'}
          </Button>
        </ModalFooter>
      }
      header={`Update Proxy ${proxy.id}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody as={Stack} gap="8px">
        {anticheatEnabled === undefined ? null : (
          <FormControl>
            <FormLabel>AntiCheat Enabled</FormLabel>
            <Switch
              id="anticheatEnabled"
              isChecked={anticheatEnabled}
              onChange={event => setAnticheatEnabled(event.target.checked)}
            />
          </FormControl>
        )}

        <FormControl>
          <FormLabel>Graceful Shutdown Barrier</FormLabel>
          <Input
            onChange={event => setGracefulShutdownBarrier(Number.parseInt(event.target.value))}
            type="number"
            value={gracefulShutdownBarrier}
          />
        </FormControl>
      </ModalBody>
    </Modal>
  )
}

export function ProxyListEntry({proxy}: {proxy: ProxyServer}): React.JSX.Element {
  const [restartConfirmationOpen, setRestartConfirmationOpen] = React.useState<boolean>(false)
  const [edit, setEdit] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const queryClient = useQueryClient()
  return (
    <Stack
      align="center"
      bgColor="gray.800"
      boxShadow="lg"
      direction="row"
      fontSize="sm"
      letterSpacing="tight"
      p={4}
      rounded="lg"
    >
      <EditProxyModal isOpen={edit} onClose={() => setEdit(false)} proxy={proxy} />
      <AlertDialog
        content={
          loading ? (
            <Text>
              Pushing update to proxy <strong>{proxy.id}</strong>...
            </Text>
          ) : proxy.state.gracefulShutdownInitiated ? (
            <>
              Cancel graceful restart of proxy <strong>{proxy.id}</strong>?
            </>
          ) : (
            <>
              Are you sure you want to gracefully restart the proxy <strong>{proxy.id}</strong>? <br /> The proxy will
              automatically shut down after the player count falls below the configured barrier.
            </>
          )
        }
        isLoading={loading}
        isOpen={restartConfirmationOpen}
        onClose={() => setRestartConfirmationOpen(false)}
        onPrimary={async () => {
          const newProxy = proxy
          newProxy.state.gracefulShutdownInitiated = !newProxy.state.gracefulShutdownInitiated

          setLoading(true)
          try {
            await api.patch(
              `/developer/proxy?intent=${newProxy.state.gracefulShutdownInitiated ? 'rollout' : 'cancel-rollout'}`,
              [newProxy],
            )

            await queryClient.invalidateQueries({queryKey: ['developer', 'proxy', 'list']})
            setRestartConfirmationOpen(false)
          } catch {
            toast.error('Failed to restart proxy')
          } finally {
            setLoading(false)
          }
        }}
        primary={proxy.state.gracefulShutdownInitiated ? 'Cancel Restart' : 'Restart Proxy'}
        title="Danger, Will Robinson!"
      />
      <Stack align="center" direction="row" flex="1" spacing="12px">
        <Stack spacing="0.5">
          <Stack align="center" direction="row" spacing="1.5">
            {proxy.state.gracefulShutdownInitiated ? (
              <Tooltip label="Proxy Restart Ongoing">
                <ArrowPathIcon
                  height={20}
                  style={{
                    backgroundColor: 'hsl(359deg 66.7% 54.1%)',
                    color: '#fff',
                    borderRadius: '3px',
                    padding: '0 4px',
                    fontSize: '14px',
                  }}
                  width={20}
                />
              </Tooltip>
            ) : (
              ''
            )}
            <Heading isTruncated size="sm">
              {proxy.id.toUpperCase()}
            </Heading>
            <Indicator variant="inverted">{proxy.state.playerCount} Players</Indicator>
            <Indicator variant="inverted">{proxy.state.production ? 'PRODUCTION' : 'STAGING'}</Indicator>
          </Stack>
          <Stack align={'center'} direction={'row'} spacing="1.5">
            <Text color="whiteAlpha.800" fontSize="sm" marginLeft="5" />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" spacing="1.5">
        <Tooltip label={proxy.state.gracefulShutdownInitiated ? 'Cancel Graceful Restart' : 'Start Graceful restart'}>
          <IconButton
            aria-label={proxy.state.gracefulShutdownInitiated ? 'Cancel Graceful Restart' : 'Start Graceful restart'}
            colorScheme="red"
            icon={
              proxy.state.gracefulShutdownInitiated ? (
                <XMarkIcon height={16} width={16} />
              ) : (
                <ArrowPathIcon height={16} width={16} />
              )
            }
            onClick={() => {
              setRestartConfirmationOpen(true)
            }}
            rounded="full"
            size="sm"
          />
        </Tooltip>
        <Tooltip label="Edit Proxy Properties">
          <IconButton
            aria-label="Edit Proxy"
            colorScheme="orange"
            icon={<WrenchIcon height={16} width={16} />}
            onClick={() => {
              setEdit(true)
            }}
            rounded="full"
            size="sm"
          />
        </Tooltip>
        <Tooltip label="Start game">
          <IconButton
            aria-label="Start Game"
            colorScheme="green"
            icon={<PlayIcon height={16} width={16} />}
            onClick={() => {}}
            rounded="full"
            size="sm"
          />
        </Tooltip>
      </Stack>
    </Stack>
  )
}
